<template>
  <div class="HexDetail">
    <H5Navheader :name="'哈希详情'" />
    <div class="hex-detail-body">
      <div class="info-detail-card"  v-loading="loading">
        <div class="title">文化数据流转哈希详情</div>
        <div class="content">
          <div class="address">
            <div class="label">交易哈希</div>
            <div class="value">
              {{hexInfo.transactionHash}}
            </div>
          </div>
          <div class="info-item">
            <div class="label">流转数量</div>
            <div class="value">{{hexInfo.count}}</div>
          </div>
          <div class="info-item">
            <div class="label">时间</div>
            <div class="value">{{hexInfo.createTime}}</div>
          </div>
        </div>
      </div>

      <div class="nft-detail-wrap-pc" v-loading="loading">
        <div class="content">
          <div class="nft-image" :style="{backgroundImage:'url('+assetsInfo.frontImageUri+')'}"></div>
          <div class="info-wrap">
            <div class="top">
              <div class="text-wrap">
                <div class="title">{{assetsInfo.name}}</div>
                <div class="text-item">
                  <span class="label">权益：</span>
                  <span class="value">{{assetsInfo.rights}}</span>
                </div>
                <div class="text-item">
                  <span class="label">标的ISLI标志码：</span>
                  <span class="value">{{assetsInfo.isliCode}}</span>
                </div>
                <div class="text-item">
                  <span class="label">委托类型：</span>
                  <span class="value">阶段性委托</span>
                </div>
              </div>
              <div class="card-wrap">
                <div class="nft-card-wrap">
                  <div class="label">卖方/授权方名称</div>
                  <div class="value">{{assetsInfo.company}}</div>
                </div>
                <div class="nft-card-wrap">
                  <div class="label">发行时间</div>
                  <div class="value">{{assetsInfo.createTime}}</div>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="nft-status">
                <div class="image i-1"></div>
                <div class="text">
                  <div class="label">持有人数</div>
                  <div class="value">{{assetsInfo.holdTotal}}</div>
                </div>
              </div>
              <div class="nft-status">
                <div class="image i-2"></div>
                <div class="text">
                  <div class="label">分发数量（个）</div>
                  <div class="value">{{assetsInfo.issueTotal}}</div>
                </div>
              </div>
              <div class="nft-status">
                <div class="image i-3"></div>
                <div class="text">
                  <div class="label">流转数量（个）</div>
                  <div class="value">{{assetsInfo.marketTotal}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nft-detail-wrap-h5" v-loading="loading">
        <div class="nft-image" :style="{backgroundImage:'url('+assetsInfo.frontImageUri+')'}"></div>
        <div class="nft-text-wrap">
          <div class="title">{{assetsInfo.name}}</div>
          <div class="text-item">
            <span class="label">权益：</span>
            <span class="value">{{assetsInfo.rights}}</span>
          </div>
          <div class="text-item">
            <span class="label">标的ISLI标志码：</span>
            <span class="value">{{assetsInfo.isliCode}}</span>
          </div>
          <div class="text-item">
            <span class="label">委托类型：</span>
            <span class="value">阶段性委托</span>
          </div>
        </div>
        <div class="nft-card-wrap">
          <div class="label">卖方/授权方名称</div>
          <div class="value">{{assetsInfo.company}}</div>
        </div>
        <div class="nft-card-wrap">
          <div class="label">发行时间</div>
          <div class="value">{{assetsInfo.createTime}}</div>
        </div>
        <div class="nft-status">
          <div class="image i-1"></div>
          <div class="text">
            <div class="label">持有人数</div>
            <div class="value">{{assetsInfo.holdTotal}}</div>
          </div>
        </div>
        <div class="nft-status">
          <div class="image i-2"></div>
          <div class="text">
            <div class="label">分发数量（个）</div>
            <div class="value">{{assetsInfo.issueTotal}}</div>
          </div>
        </div>
        <div class="nft-status">
          <div class="image i-3"></div>
          <div class="text">
            <div class="label">流转数量（个）</div>
            <div class="value">{{assetsInfo.marketTotal}}</div>
          </div>
        </div>
      </div>

      <div class="table-wrap-pc" style="width: 1200px; margin: 90px auto 0;padding-bottom:60px;">
        <div class="title">信息</div>
        <el-table :data="tableData" style="width: 100%" v-loading="loading">
          <el-table-column prop="type" label="类型" width="180">
            <template slot-scope="scope">
              <span :style="scope.row.type === '接收'?'color:#5CD062':'color:#FF7676'">{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="address" label="代理地址" width="380">
          </el-table-column> -->
          <el-table-column prop="userAddr" label="地址" width="580">
          </el-table-column>
          <el-table-column prop="count" label="流转数量"> </el-table-column>
        </el-table>
        <!-- <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          @pagination="getList"
        /> -->
      </div>

      <div class="list-wrap-h5">
        <div class="title">最新信息</div>
        <div class="card-item" v-for="item,index in tableData" :key="index">
          <div class="hex">
            <div class="text">
              {{item.userAddr}}
            </div>
            <div class="title">地址</div>
          </div>
          <div class="info">
            <div class="info-item">
              <div class="value">{{item.count}}</div>
              <div class="title">流转数量</div>
            </div>
            <div class="info-item">
              <div class="value" :style="item.type === '接收'?'color:#5CD062':'color:#FF7676'">{{item.type}}</div>
              <div class="title">类型</div>
            </div>
          </div>
        </div>
        <!-- <div class="card-item">
          <div class="hex">
            <div class="text">
              0304578c7130eea268d3ff3a625bee9a7b702f2b1fa997172260b75ebf6cb31b
            </div>
            <div class="title">区域哈希</div>
          </div>
          <div class="info">
            <div class="info-item">
              <div class="value">3</div>
              <div class="title">交易数量</div>
            </div>
            <div class="info-item">
              <div class="value">2020/1/11 下午2:13:04</div>
              <div class="title">时间</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <H5Tab :index="1" /> -->
  </div>
</template>
<script>
// import H5Tab from "@/components/H5Tab";
import H5Navheader from "@/components/H5Navheader";
import { blockHashDetail } from "@/api/index.js";

export default {
  name: "HexDetail",
  components: {
    // H5Tab,
    H5Navheader,
  },
  created() {
    this.keyword = this.$route.query.keyword
    // console.log(this.keyword)
    this.getData()
  },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 100,
      tableData: [],
      loading: true,
      keyword: '',
      hexInfo: {},
      assetsInfo: {}
    };
  },
  methods: {
    getData() {
      this.loading = true
      blockHashDetail(this.keyword).then(res => {
        console.log(res)
        this.hexInfo = {
          transactionHash: res.data.blockChainBaseInfo.transactionHash,
          count: res.data.blockChainBaseInfo.count,
          createTime: res.data.blockChainBaseInfo.createTime
        },
        this.assetsInfo = {
          name: res.data.baseInfo.name,
          rights: res.data.copyrightType?.name || '无',
          isliCode: res.data.baseInfo.isliCode,
          holdTotal: res.data.numberInfo.holdTotal,
          issueTotal: res.data.numberInfo.issueTotal,
          marketTotal: res.data.numberInfo.marketTotal,
          createTime: res.data.baseInfo.createTime,
          frontImageUri: res.data.baseInfo.frontImageUri,
          company: '深圳国夏文化数字科技有限公司'
        },
        this.tableData = [
          {type:'发送',address:res.data.blockChainBaseInfo.fromAddr,count:res.data.blockChainBaseInfo.count,userAddr:res.data.blockChainBaseInfo.userFromAddr},
          {type:'接收',address:res.data.blockChainBaseInfo.toAddr,count:res.data.blockChainBaseInfo.count,userAddr:res.data.blockChainBaseInfo.userToAddr},
        ]
        this.loading = false
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.HexDetail {
  .info-detail-card {
    background-color: #070400;
    border: 1px solid #FECD02;
    @media screen and (min-width: 1200px) {
      padding: 30px;
      width: 1200px;
      box-sizing: border-box;
      margin: 50px auto;
      > .title {
        font-size: 20px;
        padding-bottom: 19px;
        font-weight: bold;
      }
      .content {
        display: flex;
        align-items: center;

        .address {
          background: linear-gradient(90deg, #FECD02, #E7F3FF);
          border-radius: 13px;
          padding: 14px 32px 14px 12px;
          display: flex;
          align-items: center;
          .label {
            font-size: 14px;
            color: #18184c;
            font-weight: bold;
            padding-right: 54px;
          }
          .value {
            color: #18184c;
            font-size: 12px;
          }
        }
        .info-item {
          margin-left: 60px;
          .label {
            font-size: 14px;
            color: #ababff;
            padding-bottom: 12px;
          }
          .value {
            font-size: 12px;
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      word-break: break-all;
      padding: 0.3rem;
      margin: 0.5rem 0.24rem;
      > .title {
        font-size: 0.32rem;
        font-weight: bold;
        padding-bottom: 0.2rem;
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        .address {
          padding: 0.2rem 0.3rem;
          background: linear-gradient(90deg, #FECD02, #E7F3FF);
          border-radius: 0.18rem;
          .label {
            display: none;
          }
          .value {
            color: #18184c;
            font-size: 0.2rem;
          }
        }
        .info-item {
          .label {
            padding-top: 0.3rem;
            padding-bottom: 0.1rem;
            color: #ababff;
            font-size: 0.2rem;
            padding-right: 1.48rem;
          }
          &:last-child {
            .label {
              padding-right: 0;
            }
          }
          .value {
            font-size: 0.24rem;
          }
        }
      }
    }
  }
  .list-wrap-h5 {
    padding: 0.3rem 0;
    > .title {
      display: inline-block;
      font-size: 0.28rem;
      padding: 0.29rem 0.93rem;
      border-bottom: 1px solid #FECD02;
    }
    .card-item {
      padding: 0.3rem;
      margin: 0.3rem 0.24rem;
      &:nth-child(even) {
        background: #070400;
      }
      &:nth-child(odd) {
        background: #00011B;
      }
      .hex {
        .text {
          word-break: break-all;
          font-size: 0.2rem;
          color: #FECD02;
        }
        .title {
          font-size: 0.2rem;
          color: #ababff;
          padding: 0.14rem 0 0.38rem 0;
        }
      }
      .info {
        display: flex;
        align-items: center;
        .info-item {
          &:nth-child(1) {
            .title {
              padding-right: 0.89rem;
            }
          }
          .value {
            font-size: 0.24rem;
          }
          .title {
            font-size: 0.2rem;
            color: #ababff;
            padding-top: 0.1rem;
          }
        }
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
  .nft-detail-wrap-pc {
    background: #3C2E00;
    .content {
      width: 1200px;
      margin: auto;
      padding: 40px 0;
      display: flex;
      align-items: center;
      .nft-image {
        width: 324px;
        height: 338px;
        background-image: url("../assets/h5_img.png");
        background-size: cover;
        background-position: center center;
        border-radius: 8px;
      }
      .info-wrap {
        padding: 0 34px;
        .top {
          display: flex;
          .text-wrap {
            .title {
              font-size: 30px;
              font-weight: bold;
              padding-bottom: 34px;
            }
            .text-item {
              padding-bottom: 14px;
              font-size: 14px;
              .label {
                font-weight: bold;
              }
            }
          }
          .card-wrap {
            margin-left: 90px;
            .nft-card-wrap {
              background: #FECD02;
              width: 372px;
              box-sizing: border-box;
              padding: 15px 34px;
              border-radius: 13px;
              margin-bottom: 14px;
              .label {
                color: #9191cb;
                font-size: 14px;
                padding-bottom: 10px;
              }
              .value {
                font-size: 16px;
              }
            }
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          padding-top: 40px;
          .nft-status {
            display: flex;
            align-items: center;
            .image {
              width: 40px;
              height: 35px;
              background-size: cover;
              background-position: center center;
              &.i-1 {
                background-image: url("../assets/details_icon_people.png");
              }
              &.i-2 {
                background-image: url("../assets/details_icon_issue.png");
              }
              &.i-3 {
                background-image: url("../assets/details_icon_circulation.png");
              }
            }
            .text {
              padding-left: 16px;
              .label {
                font-size: 12px;
                padding-bottom: 10px;
                padding-right: 77px;
              }
              .value {
                font-size: 20px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .nft-detail-wrap-h5 {
    background: #3C2E00;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.4rem 0.24rem;
    .nft-image {
      width: 3.24rem;
      height: 3.38rem;
      border-radius: 8px;
      background-image: url("../assets/h5_img.png");
      background-size: cover;
      background-position: center center;
    }
    .nft-text-wrap {
      word-break: break-all;
      width: 3.46rem;
      .title {
        font-size: 0.3rem;
        font-weight: bold;
        padding-bottom: 0.2rem;
      }
      .text-item {
        .label {
          font-size: 0.2rem;
          font-weight: bold;
        }
        .value {
          font-size: 0.2rem;
        }
      }
    }
    .nft-card-wrap {
      width: 3.46rem;
      box-sizing: border-box;
      padding: 0.14rem 0 0.14rem 0.14rem;
      font-size: 0.2rem;
      background: #FECD02;
      border-radius: 0.13rem;
      margin: 0.3rem 0;
      .label {
        color: #9898ce;
        padding-bottom: 0.09rem;
      }
      .value {
        word-break: break-all;
        line-height: 0.24rem;
      }
    }
    .nft-status {
      display: flex;
      align-items: center;
      .image {
        width: 0.5rem;
        height: 0.45rem;
        background-size: cover;
        background-position: center center;
        &.i-1 {
          background-image: url("../assets/h5_details_icon_people.png");
        }
        &.i-2 {
          background-image: url("../assets/h5_details_icon_issue.png");
        }
        &.i-3 {
          background-image: url("../assets/h5_details_icon_circulation.png");
        }
      }
      .text {
        padding-left: 0.25rem;
        .label {
          font-size: 0.2rem;
          padding-bottom: 0.08rem;
        }
        .value {
          font-size: 0.24rem;
          font-weight: bold;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
</style>
