<template>
  <div class="search-page">
    <div class="search-box">
      <el-input
        placeholder="请输入交易哈希或者钱包地址"
        prefix-icon="el-icon-search"
        v-model="keyword"
        clearable
        @keyup.enter.native="handleQuery"
      >
      </el-input>
      <div class="button" @click="handleQuery">搜索</div>
    </div>
    <div class="no-content" v-if="show">
      <div class="image"></div>
      <div class="text">搜索不到对应信息</div>
    </div>
  </div>
</template>

<script>
import { searchAddrHash } from '@/api/index.js'

export default {
  data() {
    return {
      keyword: "",
      show: false,
    };
  },
  methods: {
    handleQuery() {
      console.log(this.keyword)
      searchAddrHash({searchAddrHash:this.keyword}).then(res => {
        console.log(res)
        if (res.code === 500) {
          this.$message.error(res.msg);
          this.show = true
        }
        if (res.msg === 'BLOCK_HASH') {
          this.$router.push({path: 'hexDetail',query: {keyword:this.keyword,t: Date.now()}});
        }
        if (res.msg === 'ADDR_DETAIL') {
          this.$router.push({path: 'address',query: {keyword:this.keyword,t: Date.now()}});
        }
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.search-page {
  .search-box {
    padding: 0.14rem 0.24rem;
    display: flex;
    background: #27277B;
    align-items: center;
    .button {
      font-size: 0.26rem;
      flex-shrink: 0;
      padding-left: 0.32rem;
    }
  }
  .no-content {
    .image {
      width: 3.82rem;
      height: 2.31rem;
      background-size: cover;
      background-image: url('../assets/h5_search_no.png');
      margin: 3rem auto 0.35rem;
    }
    .text {
      text-align: center;
      font-size: 0.26rem;
      color: #939395;
    }
  }
}
</style>
<style lang="scss">
.search-page {
  .search-box {
    .el-input__inner {
      width: 6.16rem;
      box-sizing: border-box;
      border: 0;
      border-radius: 0.36rem;
      background: #353594;
      color: #fff;
      &:focus {
        border-color: #409EFF;
      }
    }
    // .el-input__icon {
    //   color: #FECD02;
    // }
  }
}
</style>